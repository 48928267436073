:root {
    --black: #1E1E1E;
    --white: #FFFFFF;
    --gray: #5B5B5B;
    --gray_50: #5b5b5b79;
    --gray_10: rgba(91, 91, 91, 0.1);
    --orange: #FFAD42;
    --blue: #4588F5;
    --red: #FF4D4D;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    background-color: var(--black);
    letter-spacing: 1.5px;
}

button {
    cursor: pointer;
    background: none;
    border: none;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1.5px;
    font-weight: 300;
    font-size: 16px;
}

a {
    color: var(--orange);
    cursor: pointer;
}

a:hover {
    color: var(--blue);
}

.button-link {
    color: var(--orange);
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
}

.button-link:hover {
    color: var(--blue);
}

h1 {
    font-size: 32px;
}

@media screen and (max-width: 800px) {
    h1 {
        font-size: 28px;
    }
}

h2 {
    font-size: 26px;
}

@media screen and (max-width: 800px) {
    h2 {
        font-size: 22px;
    }
}

h3 {
    font-size: 19px;
}

@media screen and (max-width: 800px) {
    h3 {
        font-size: 16px;
    }
}

.full-screen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.flex-1 {
    flex: 1;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.orange {
    color: var(--orange);
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: start;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline !important;
}

.flex-row--reverse-800 {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 800px) {
    .flex-row--reverse-800 {
        flex-direction: column;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.width-80 {
    width: 80%;
}

@media screen and (max-width: 800px) {
    .width-80 {
        width: 100%;
    }
}

.double-border-component {
    background-color: var(--orange);
    display: flex;
    flex: 0.5;
    height: 100vh;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    box-shadow: 0 0 20px var(--black);
}

.double-border-component > div {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    flex: 0.95;
    height: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    box-shadow: 0 0 10px var(--black);
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.double-border-component > div::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 800px) {
    .double-border-component {
        margin-top: 33%;
        padding-top: 30px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 20px var(--black);
    }

    .double-border-component > div {
        flex: 1;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 10px var(--black);
    }
}

.full-width {
    width: 100%;
}

.orange-button {
    background-color: var(--orange);
    width: 100%;
    padding-inline: 8px;
    padding-block: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 0;
    font-weight: bold;
    color: var(--white);
    box-shadow: 1px 2px 5px var(--black);
}

.orange-button:hover {
    box-shadow: 0 0 0 var(--black);
}

.orange-button:disabled {
    box-shadow: 0 0 0 var(--black);
    opacity: 0.5;
    cursor: default;
}

.red-button {
    background-color: var(--red);
    width: 100%;
    padding-inline: 8px;
    padding-block: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 0;
    font-weight: bold;
    color: var(--white);
    box-shadow: 1px 2px 5px var(--black);
}

.red-button:hover {
    box-shadow: 0 0 0 var(--black);
}

.red-button:disabled {
    box-shadow: 0 0 0 var(--black);
    opacity: 0.5;
    cursor: default;
}

.button-link-orange {
    color: var(--orange);
    cursor: pointer;
    text-decoration: underline;
}

.button-link-orange:hover {
    color: var(--blue);
}

.button-link-gray {
    color: var(--gray);
    cursor: pointer;
    text-decoration: underline;
}

.button-link-gray:hover {
    color: var(--orange);
}

.white-card {
    background-color: var(--white);
    width: 40%;
    overflow-y: auto;
    max-height: 50%;
    border: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
}

@media screen and (max-width: 800px) {
    .white-card {
        width: 75%;
    }
}

.full-screen-white-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-block: 40px;
    margin-inline: 80px;
    padding: 40px;
    border: 0;
    box-shadow: 0 0 10px var(--gray_50);
    border-radius: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.full-screen-white-card > div::-webkit-scrollbar {
    display: none;
}

.grid-2-columns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 60px;
}

@media screen and (max-width: 800px) {
    .grid-2-columns {
        grid-template-columns: auto;
    }
}

.grid-3-columns {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 60px;
}

@media screen and (max-width: 800px) {
    .grid-3-columns {
        grid-template-columns: auto;
    }
}

.grid-4-columns {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 60px;
}


@media screen and (max-width: 1200px) {
    .grid-4-columns {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 800px) {
    .grid-4-columns {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 800px) {
    .full-screen-white-card {
        margin-block: 30px;
        margin-inline: 20px;
        padding: 20px;
    }
}

/* ANIMATIONS */
@keyframes fade-in {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }
}

.selected-gray {
    background-color: var(--gray_10);
}